.header-table-container {
    display: flex;
    flex-direction: column
}

.header-table-text {
    color: #fff;
    font-family: 'gotham-medium', sans-serif;
    line-height: 1.15;
    @include responsive-font-size(1024px, 1300px, 12px, 14px);
    letter-spacing: -0.16px;
    text-transform: uppercase;
    margin-bottom: 8px;
    overflow-wrap: break-word;
    padding-right: 2px;
}

.funeral-row-text {
    color: var(--color-dark-green-2d);
    @include responsive-font-size(1024px, 1400px, 14px, 18px);
    font-family: 'klima-regular', sans-serif;
    display: flex;
    padding-left: 13.5px;
    padding-right: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    align-items: center;
    background-color: rgba(#fff, 0.75);
    letter-spacing: -0.21px;

    &:not(:last-child) {
        border-right: 1px solid var(--color-dark-green-57);
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &.name {
        @include responsive-font-size(1024px, 1400px, 16px, 22px);
        font-family: 'klima-medium', sans-serif;
        color: var(--color-dark-green-21);
        letter-spacing: -0.25px;
        padding-left: 31px;
    }

    &.debut {
        color: var(--color-dark-green-26);
        @include responsive-font-size(1024px, 1400px, 14px, 19px);
    }
}

.urnNotReady {
    .funeral-row-text {
        color: var(--color-dark-red-67);
    }

}