.number {
    font-family: 'klima-regular', sans-serif;

    .row {
        .item {
            width: 130px;
            height: 130px;
            background-color: #8DB919;
            color: white;
            float: left;
            margin: 8px;
            position: relative;
            border-radius: 8px;
            cursor: pointer;

            &.double {
                width: 275px;
            }

            &.close {
                background-color: #D8D8D8;
                color: red;
            }

            .action-number {

                text-align: center;
                font-size: 48pt;
                width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.modal-main {
    width: 438px !important;
}