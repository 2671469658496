@font-face {
    font-family: 'gotham-medium';
    src: url('../../assets/fonts/gotham/GothamMedium.eot');
    src: url('../../assets/fonts/gotham/GothamMedium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/gotham/GothamMedium.woff2') format('woff2'),
        url('../../assets/fonts/gotham/GothamMedium.woff') format('woff'),
        url('../../assets/fonts/gotham/GothamMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'klima-bold';
    src: url('../../assets/fonts/klima/klima-bold-web.eot');
    src: url('../../assets/fonts/klima/klima-bold-web.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/klima/klima-bold-web.woff2') format('woff2'),
        url('../../assets/fonts/klima/klima-bold-web.woff') format('woff'),
        url('../../assets/fonts/klima/klima-bold-web.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'klima-regular';
    src: url('../../assets/fonts/klima/klima-regular-web.eot');
    src: url('../../assets/fonts/klima/klima-regular-web.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/klima/klima-regular-web.woff2') format('woff2'),
        url('../../assets/fonts/klima/klima-regular-web.woff') format('woff'),
        url('../../assets/fonts/klima/klima-regular-web.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'klima-medium';
    src: url('../../assets/fonts/klima/klima-medium-web.eot');
    src: url('../../assets/fonts/klima/klima-medium-web.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/klima/klima-medium-web.woff2') format('woff2'),
        url('../../assets/fonts/klima/klima-medium-web.woff') format('woff'),
        url('../../assets/fonts/klima/klima-medium-web.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

:root {
    --color-gray-97: #979797;
    --color-gray-d8: #D8D8D8;
    --color-light-green-95: #95B63D;
    --color-light-green-a3: #A3CE2E;
    --color-light-green-8d: #8DB919;
    --color-dark-green-57: #577652;
    --color-dark-green-2d: #2D491B;
    --color-dark-green-1f: #1F201F;
    --color-dark-green-45: #455024;
    --color-dark-green-56: #567110;
    --color-dark-green-21: #213610;
    --color-dark-green-26: #263519;
    --color-dark-green-67: #678B09;
    --color-dark-red-67: #b71c1c ;
}

$max-width-size: 1820px;