.table-view-container {
    .table-header-title {
        color: #fff;
        @include responsive-font-size(1024px, 1800px, 32px, 45px);
        font-family: 'klima-regular', sans-serif;
        height: 45px;
        line-height: 0.98;
    }

    .table-header-subtitle {
        color: var(--color-light-green-95);
        @include responsive-font-size(1024px, 1800px, 32px, 45px);
        font-family: 'klima-regular', sans-serif;
        height: 45px;
        line-height: 0.98;
    }
}


.list-item {
    @include responsive-font-size(1024px, 1800px, 16px, 18px);
    display: flex;
    flex-wrap: wrap;
    padding: 6px 6px 6px 14px;
    align-items: center;
    font-family: 'klima-regular', sans-serif;
    letter-spacing: -0.21px;
    &.time {
        color: var(--color-dark-green-26);
        @include responsive-font-size(1024px, 1800px, 16px, 19px);
    }

    &.name {
        color: var(--color-dark-green-21);
        letter-spacing: -0.31px;
        font-family: 'klima-medium', sans-serif;
        @include responsive-font-size(1024px, 1800px, 18px, 26px);
    }

    &.bg-green {
        background-color: var(--color-light-green-95);
    }

    &.bg-white {
        background-color: #fff;
    }

    &.color-green {
        color: #567110;
    }

    &.color-white {
        color: #fff;
    }

    .arrow {
       max-width: 16px;
       margin: 0 4px;
    }

    &:not(:last-child) {
        border-right: 1px solid #577652;
    }
    
    &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-left: 30px;
        border-right: none;
    }

    &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .funeral-house-name{
        @include responsive-font-size(1024px, 1800px, 16px, 18px);
        font-family: 'klima-regular', sans-serif;
        letter-spacing: -0.34px;
        height: 30px;
        margin-top: 10px;
        color: var(--color-dark-green-67);
        &.small{
            font-size: 15px;
        }
    }
}