.grid-container {
    display: grid;
    grid-template-columns: 40px repeat(12, 1fr) 330px 40px;
    grid-template-rows: 30px auto 15px;
    column-gap: 20px;
    row-gap: 30px;
    height: 100%;
    min-height: 100vh;
    padding-top: 210px;

    @include responsive(1400) {
        row-gap: 15px;
        column-gap: 10px;
        grid-template-rows: 30px 1fr 15px;
    }
}

.grid-container-clock {
    grid-row: 1;
    position: relative;
    background-color: rgba(#95B63D, 0.51);

    &.top-right {
        grid-column: 14 / 16;
        z-index: 2;
    }
}

.grid-clock-top-right-container {
    position: relative;
    z-index: 3;
}

.grid-table-container {
    padding: 240px 0 45px 0;
}

.funeral-header-container {
    display: flex;
    padding: 40px 60px;
    background-color: rgba(#1F201F, 0.62);
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 210px;

    &:after {
        content: '';
        position: absolute;
        width: 390px;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 1;
        background-color: rgba(#95B63D, 0.51);
    }
}

.grid-header-background {
    grid-column: 1 / 16;
    grid-row: 1;
    opacity: 0.62;
    position: relative;
    background-color: var(--color-dark-green-1f);
    z-index: 1;
}

.grid-container-title {
    &.top {
        grid-column: 2 / 12;
        grid-row: 1;
        position: relative;
        z-index: 2;
    }
}


.grid-container-content {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}


.grid-container-content-header {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}

.grid-container-main {
    width: 100%;
    height: 100%;
    padding: 0 60px;
    max-height: calc(100vh - 345px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.grid-container-header {
    padding: 0 60px;
    display: flex;

    .header-table-container {
        margin-bottom: 40px;
        &:nth-child(1) {
            flex: 0 0 18%;
            max-width: 18%;
        }

        &:nth-child(2) {
            flex: 0 0 16%;
            max-width: 16%;
        }

        &:nth-child(3) {
            flex: 0 0 8%;
            max-width: 8%;
        }

        &:nth-child(4) {
            flex: 0 0 8%;
            max-width: 8%;
        }

        &:nth-child(5) {
            flex: 0 0 8%;
            max-width: 8%;
        }

        &:nth-child(6) {
            flex: 0 0 10%;
            max-width: 10%;
        }

        &:nth-child(7) {
            flex: 0 0 9%;
            max-width: 9%;
        }

        &:nth-child(8) {
            flex: 0 0 9%;
            max-width: 9%;
        }

        &:nth-child(9) {
            flex: 0 0 14%;
            max-width: 14%;
        }
    }
}

.grid-container-main-funeral-row {
    display: flex;
    margin-bottom: 15px;
    width: 100%;

    .funeral-row-text{
        min-height: 60px;
        &:nth-child(1) {
            flex: 0 0 18%;
            max-width: 18%;
        }

        &:nth-child(2) {
            flex: 0 0 16%;
            max-width: 16%;
        }

        &:nth-child(3) {
            flex: 0 0 8%;
            max-width: 8%;
        }

        &:nth-child(4) {
            flex: 0 0 8%;
            max-width: 8%;
        }

        &:nth-child(5) {
            flex: 0 0 8%;
            max-width: 8%;
        }

        &:nth-child(6) {
            flex: 0 0 10%;
            max-width: 10%;
        }

        &:nth-child(7) {
            flex: 0 0 9%;
            max-width: 9%;
        }

        &:nth-child(8) {
            flex: 0 0 9%;
            max-width: 9%;
        }

        &:nth-child(9) {
            flex: 0 0 14%;
            max-width: 14%;
        }
    }

    &.skeleton-loading {
        grid-template-columns: 100%;
    }
}


.container-main-planning {
    height: 100%;
    min-height: 100vh;
    display: flex;
    width: 100%;
    .container-left-sidebar,
    .container-right-main{
        height: 100%;
    }

    .container-left-sidebar{
        flex: 0 0 23%;
        max-width: 23%;
    }

    .container-right-main{
        flex: 0 0 77%;
        max-width: 77%;
    }

}

.container-left-title {
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: 32px;
    padding-left: 29px;
}

.container-left-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 60px;
    background-color: rgba(#1F201F, 0.62);
    min-height: 100vh;
}

.container-right-main {
    padding: 60px 60px 45px 120px;
    max-height: 100vh;
    overflow-y: auto;

    @include responsive(1400) {
        padding: 60px 60px 15px 60px;
    }

    &.ceremony {
        padding: 60px 60px 30px 120px;

        @include responsive(1400) {
            padding: 60px 60px 15px 60px;
        }

        .table-view-container{
            min-height: unset;
        }
    }
}

.container-left-clock {
    height: 240px;
    margin-top: auto;
    background-color: rgba(#95B63D, 0.51);
    position: relative;
}

.table-view-container {
    min-height: 315px;
    &.upcoming {
        margin-top: 45px;
    }
    &.waiting {
        margin-top: 0px;
    }
}


.table-grid-container-head {
    display: flex;
    width: 100%;
    margin-top: 15px;
    
    .table-grid-row-head{
        min-height: 30px;
        margin-bottom: 15px;

        &:nth-child(1) {
            flex: 0 0 18%;
            max-width: 18%;
        }

        &:nth-child(2) {
            flex: 0 0 46%;
            max-width: 46%;
        }

        &:nth-child(3) {
            flex: 0 0 18%;
            max-width: 18%;
        }

        &:nth-child(4) {
            flex: 0 0 18%;
            max-width: 18%;
        }
    }

    &.upcoming {
        .table-grid-row-head{

            &:nth-child(1) {
                flex: 0 0 18%;
                max-width: 18%;
            }

            &:nth-child(2) {
                flex: 0 0 41%;
                max-width: 41%;
            }

            &:nth-child(3) {
                flex: 0 0 13%;
                max-width: 13%;
            }

            &:nth-child(4) {
                flex: 0 0 15%;
                max-width: 15%;
            }

            &:nth-child(5) {
                flex: 0 0 13%;
                max-width: 13%;
            }
        }
    }

    &.waiting {

        .table-grid-row-head{
            &:nth-child(1) {
                flex: 0 0 18%;
                max-width: 18%;
            }

            &:nth-child(2) {
                flex: 0 0 36%;
                max-width: 36%;
            }

            &:nth-child(3) {
                flex: 0 0 28%;
                max-width: 28%;
            }

            &:nth-child(4) {
                flex: 0 0 18%;
                max-width: 18%;
            }
        }

        
    }

    .table-grid-row-head {
        font-size: 14px;

        .row-title {
            color: #fff;
            font-family: 'gotham-medium', sans-serif;
            letter-spacing: -0.16px;
            text-transform: uppercase;
        }

        .row-subtitle {
            color: #8DB919;
            font-family: 'gotham-medium', sans-serif;
            letter-spacing: -0.16px;
            text-transform: uppercase;
        }
    }
}

.table-grid-container-progress {
    display: flex;
    flex-direction: column;

    .table-grid-container-column{
        margin-bottom: 15px;
        min-height: 60px;
    }

}

.table-grid-container-column {
    list-style-type: none;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
    display: flex;

    .list-item{
        &:nth-child(1) {
            flex: 0 0 18%;
            max-width: 18%;
        }

        &:nth-child(2) {
            flex: 0 0 46%;
            max-width: 46%;
        }

        &.bethune{
            &:nth-child(3) {
                flex: 0 0 36%;
                max-width: 36%;
            }
        }

        &:nth-child(3) {
            flex: 0 0 18%;
            max-width: 18%;
        }

        &:nth-child(4) {
            flex: 0 0 18%;
            max-width: 18%;
        }
    }

    &.upcoming {

        .list-item{
            &:nth-child(1) {
                flex: 0 0 18%;
                max-width: 18%;
            }

            &:nth-child(2) {
                flex: 0 0 41%;
                max-width: 41%;
            }

            &:nth-child(3) {
                flex: 0 0 13%;
                max-width: 13%;
            }

            &.bethune {
                &:nth-child(4) {
                    flex: 0 0 28%;
                    max-width: 28%;
                }
            }

            &:nth-child(4) {
                flex: 0 0 15%;
                max-width: 15%;
            }

            &:nth-child(5) {
                flex: 0 0 13%;
                max-width: 13%;
            }
        }
    }

    &.waiting {
        .list-item{
            &:nth-child(1) {
                flex: 0 0 18%;
                max-width: 18%;
            }

            &:nth-child(2) {
                flex: 0 0 36%;
                max-width: 36%;
            }

            &:nth-child(3) {
                flex: 0 0 28%;
                max-width: 28%;
            }

            &:nth-child(4) {
                flex: 0 0 18%;
                max-width: 18%;
            }
        }
    }
}

.card-ceremony-container {
    margin-top: 60px;

    .card{
        margin-bottom: 30px;
    }

    .card-primary{
        height: 380px;
    }

    .card-secondary{
        height: 260px;
    }

    @include responsive(1400) {
        margin-top: 30px;
        .card-primary{
            height: 220px;
        }

        .card-secondary{
            height: 180px;
        }
    }

    &.waiting {

        .card-primary{
            height: 340px;
        }

        .card-secondary{
            height: 220px;
        }

        @include responsive(1400) {
            margin-top: 30px;
            .card{
                margin-bottom: 30px;
            }

            .card-primary{
                height: 220px;
            }

            .card-secondary{
                height: 180px;
            }
        }
    }
}