.title {
    font-family: 'klima-medium', sans-serif;
    color: var(--color-gray-d8);
    @include responsive-font-size(1024px, 1800px, 32px, 56px);
    line-height: 1.15;
    letter-spacing: -0.65px;
    &.title-room {
        display: flex;
        flex-direction: column;
    }
}

.subtitle {
    font-family: 'klima-medium', sans-serif;
    color: var(--color-light-green-95);
    @include responsive-font-size(1024px, 1800px, 32px, 56px);
    line-height: 1.15;
    letter-spacing: -0.65px;
    &.title-room {
        display: flex;
        flex-direction: column;
    }
}

.funeral-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}