.day-date-clock-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.day-date-container {
    display: flex;

    .day-container {
        font-family: 'klima-medium', sans-serif;
        font-size: 12px;
        color: #fff;
        display: flex;
        align-items: center;
    }

    .date-container {
        font-family: 'klima-regular', sans-serif;
        font-size: 30px;
        color: #fff;
        margin-left: 20px;
    }
}

.clock-container {
    font-size: 103px;
    font-family: 'klima-regular', sans-serif;
    color: var(--color-light-green-a3);
    @include responsive-font-size(1024px, 1400px, 82px, 103px);
    margin-top: 15px;

    p {
        width: 100%;
        letter-spacing: -1.18px;
        line-height: 0.96;
        height: 80px;
    }
}

.tick-animation {
    animation: tickAnimation 1000ms ease-in-out infinite both;
}

.container-center-clock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}