.btn {
    &.btn-edit-waiting {
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        justify-self: end;
        border: none;
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 8px;
    }
}