.card {
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.5);
    &.card-primary {
        background-color: var(--color-light-green-95);
    }

    &.card-secondary {
        background-color: rgba(#fff, 0.75);
    }

    .card-content-container {
        display: grid;
        grid-template-rows: 90px 1fr;
        grid-template-columns: 1fr 0.5fr;
        grid-template-areas: 'date-name image' 'date-name image';
        height: 100%;

        .card-title-container {
            margin-left: 61px;
            border-left: 2px solid var(--color-dark-green-21);
            padding-left: 27px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            grid-area: 'date-name';
            .time {
                font-family: 'klima-regular', sans-serif;
                @include responsive-font-size(1024px, 1400px, 20px, 32px);
                color: #fff;
                line-height: 0.9;
                .arrow {
                    display: inline-flex;
                    max-width: 24px;
                }
            }

            .card-title {
                @include responsive-font-size(1024px, 1800px, 20px, 30px);
                font-family: 'klima-regular', sans-serif;
                letter-spacing: -0.34px;
                height: 30px;
                &:not(:first-child) {
                    margin-top: 8px;
                }

                &.fr {
                    color: var(--color-dark-green-2d);
                }

                &.de {
                    color: var(--color-dark-green-67);
                }
            }
        }

        .card-body-name-container {
            margin-left: 58px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            padding-bottom: 45px;
            @include responsive(1400) {
                padding-bottom: 15px;
            }
            .civility {
                letter-spacing: -0.55px;
                @include responsive-font-size(1024px, 1800px, 24px, 48px);
                font-family: 'klima-regular', sans-serif;
                color: var(--color-dark-green-26);
            }

            .name {
                letter-spacing: -1.17px;
                @include responsive-font-size(1024px, 1800px, 28px, 52px);
                font-family: 'klima-bold', sans-serif;
                color: var(--color-dark-green-26);
            }

            .room-name {
                letter-spacing: -0.55px;
                @include responsive-font-size(1024px, 1800px, 28px, 48px);
                font-family: 'klima-regular', sans-serif;
                color: var(--color-dark-green-21);
            }
            .funeral-house-name {
                @include responsive-font-size(1024px, 1800px, 20px, 30px);
                font-family: 'klima-regular', sans-serif;
                letter-spacing: -0.34px;
                height: 30px;
                margin-top: 10px;
                color: var(--color-dark-green-67)
            }
        }

        .card-body-picture-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            grid-area: image;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

        }
    }
}