@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/*
    $min-vw             : minimum viewport width
    $max-vw             : maximum viewport height
    $min-font-size      : minimum font size
    $max-font-size      : maximum font size  
*/

@mixin responsive-font-size($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
        font-size: $min-font-size;
        @media screen and (min-width: $min-vw) {
            font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
        }
        @media screen and (min-width: $max-vw) {
            font-size: $max-font-size;
        }
        }
    }
}

@function get-line-height($font-size, $line-height) {
    @return $line-height / $font-size;
}

@mixin responsive($breakpoint) {

    @if $breakpoint==1024 {
        @media only screen and (max-width: 1024px) {
            @content
        }; //1024px
    }

    @if $breakpoint==1280 {
        @media only screen and (max-width: 1280px) {
            @content
        }; //1280px 
    }

    @if $breakpoint==1320 {
        @media only screen and (max-width: 1320px) {
            @content
        }; //1320px 
    }

    @if $breakpoint==1400 {
        @media only screen and (max-width: 1366px) {
            @content
        }; //1320px 
    }


    @if $breakpoint==ms-edge {
        @supports (-ms-ime-align: auto) {
            @content
        }; //microsoft edge styling
    }

    @if $breakpoint==chrome-only {
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            @content
        };
    }

    @if $breakpoint==full-screen {
        @media all and (display-mode: fullscreen) {
            @content
        };
    }
}